
export default {
    methods:{
        showError(err){
            //console.log(err);
            if (typeof err.response.data.error === 'object') {
                for(let i in err.response.data.error){
                    for(let a in err.response.data.error[i]) {
                        this.$noty.error(err.response.data.error[i][a]);
                    }
                }
            } else {

                if (err.response.data.code === 401)
                    this.$store.dispatch('logout').then(()=>{
                        if (this.$router.currentRoute.name !== 'login')
                            this.$router.push({name:'login'})
                    });

                this.$noty.error(err.response.data.error);
            }
        }
    }
};
