import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueNoty from 'vuejs-noty' // https://github.com/renoguyon/vuejs-noty
import 'vuejs-noty/dist/vuejs-noty.css';
import errorHandler from "./mixins/errorHandler";
import axios from 'axios'

// Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(VueNoty, {
    timeout: 4000,
    theme:'metroui',
    progressBar: true,
    layout: 'topRight'
});
Vue.mixin(errorHandler);

router.beforeEach((to, from, next) => {
    //Authorization
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getToken;
    next();
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
