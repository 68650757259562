import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from "../store/store";



const auth = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next();
        return
    }
    next({name:'login'})
};

const guest = (to, from, next) => {
    if (!store.getters.isLoggedIn) {
        next();
        return
    }
    next({name:'home'})
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter:guest
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    beforeEnter:auth
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
