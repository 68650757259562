import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

export default {
    state: {
        tokens: JSON.parse(localStorage.getItem('tokens')) || null,
        user : JSON.parse(localStorage.getItem('identity')) || {},
    },
    mutations: {
        AUTH_SUCCESS(state, {tokens, user}){
            state.tokens = tokens;
            state.user = user;
        },
        LOGOUT(state){
            state.tokens = null;
            state.user = {};
        },
    },
    actions: {
        login({commit}, user){
            return new Promise((resolve, reject) => {
                axios.post('/api/login', user)
                    .then(res => {
                        // Set Tokens
                        localStorage.setItem('tokens',JSON.stringify(res.data.data.token));

                        // Set User
                        localStorage.setItem('identity',JSON.stringify(res.data.data.user));

                        commit('AUTH_SUCCESS', {tokens: res.data.data.token, user:res.data.data.user});
                        resolve()
                    })
                    .catch(err => {
                        localStorage.removeItem('tokens');
                        localStorage.removeItem('identity');
                        reject(err)
                    })
            })
        },
        externalLogin(user){
            return new Promise((resolve, reject) => {
                axios.post('/api/external_login', user)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        register(user) {
            return new Promise((resolve, reject) => {
                axios.post('/api/register', user)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('LOGOUT');
                localStorage.removeItem('tokens');
                localStorage.removeItem('identity');
                resolve()
            })
        },
        getExternalToken() {
            return new Promise((resolve, reject) => {
                axios.get('/api/external_token')
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },
    getters : {
        getToken(state) {
            if (!state.tokens)
                return null;

            if(Date.now() > parseInt(state.tokens.expires_at)){
                return null;
            }
            else {
                return state.tokens.access_token;
            }
        },
        isLoggedIn(state, getters) {
            return getters.getToken != null;
        },
        getUser(state) {
            return state.user;
        }
    }
};
