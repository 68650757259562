<template>
  <div class="container h-100">
      <div class="row h-100 align-items-center justify-content-center">
          <div class="col-10 col-md-8">
              <div class="card mb-3">
                  <div class="row no-gutters">
                      <div class="col-md-4 bg-info card-header-floatting text-center p-4 text-white">
                          <img src="img/logo.png" class="img-fluid mx-auto d-block" style="max-width: 150px">
                          <p class="mt-4 m-0 p-0">Ingrese a todas nuestras Apps desde un solo lugar!</p>
                      </div>
                      <div class="col-md-8">
                          <div class="card-body  h-100 w-100">
                              <form class="row h-100 w-100 no-gutters" @submit.prevent="login">
                                  <div class="col-12 align-self-center">
                                      <div class="form-group">
                                          <label class="bmd-label-static">Email</label>
                                          <input type="email" class="form-control" placeholder="Ingrese su email" v-model="form.email">
                                      </div>
                                      <div class="form-group">
                                          <label class="bmd-label-static">Password</label>
                                          <input type="password" class="form-control" placeholder="Ingrese su password" v-model="form.password">
                                      </div>

                                  </div>
                                  <div class="col-12  align-self-end">
                                      <button class="btn btn-info btn-block active">INGRESAR</button>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            form:{
                email:null,
                password:null,
            }
        }
    },
    methods: {
        login() {
            if (!this.form.email || !this.form.password) {
                this.$noty.error('Debe completar el formulario antes de enviar!');
            } else {
                this.$store
                    .dispatch("login", this.form)
                    .then(() => {
                        this.$router.push({name:'home'})
                    })
                    .catch(err => this.showError(err));
            }
        }
    }
}
</script>

<style lang="scss">
    .card-header-floatting {
        border-radius: .5em;
        -webkit-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.4);
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.4);
        margin: -10px;

        @media (max-width: 768px) {
            margin-bottom: 0;
            width: 110% !important;
        }
        @media (min-width: 768px) {
            margin-right: 0;
        }
    }
    b,strong {
        font-weight: bold !important;
    }
</style>
